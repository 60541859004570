<template>
  <div
    id="scrollbar"
    class="packet-option"
  >
    <v-container>
      <div class="packet-header d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row px-1 py-4">
        <div class="header-left d-flex">
          <ul>
            <li class="font-weight-bold">
              Nama Paket
            </li>
            <li class="font-weight-bold">
              Deskripsi
            </li>
            <li class="font-weight-bold">
              Jenjang
            </li>
          </ul>
          <ul class="ml-4">
            <li class="font-weight-medium">
              : {{ packet.name }}
            </li>
            <li class="font-weight-medium">
              : {{ packet.description }}
            </li>
            <li class="font-weight-medium">
              : {{ packet.level_name }}
            </li>
          </ul>
        </div>
        <div class="header-right d-flex ml-sm-10 ml-md-10 ml-lg-10 ml-xl-10">
          <ul class="ml-0 pl-0">
            <li class="font-weight-bold">
              Harga
            </li>
            <li class="font-weight-bold">
              Jumlah Buku
            </li>
          </ul>
          <ul class="ml-4">
            <li class="font-weight-medium">
              : Rp. {{ price }}
            </li>
            <li class="font-weight-medium">
              : {{ librariesToAdd.length ? librariesToAdd.length : 0 }}
            </li>
          </ul>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="packet-title d-flex align-center justify-space-between px-1 my-4">
        <v-col
          cols="6"
          class="pl-0"
        >
          <h1 class="font-weight-bold">
            Pilih Buku
          </h1>
          <p class="mb-0">
            Cari dan pilih buku sesuai kebutuhan paket
          </p>

          <!-- <div class="d-flex align-center">
            Pilih Semua dari kategori : <v-switch
              v-model="isSelectAll"
              class="ml-4"
              @change="checkButton"
            ></v-switch>
          </div> -->
        </v-col>
        <v-col
          cols="6"
          class="pr-0"
        >
          <v-text-field
            v-model="search"
            label="Cari"
            single-line
            hide-details
            dense
            outlined
            :append-icon="icons.mdiMagnify"
          ></v-text-field>
        </v-col>
        <div>
        </div>
      </div>
      <v-row class="px-1">
        <v-col
          md="6"
          cols="12"
        >
          <v-card
            outlined
            height="475"
          >
            <v-card-title>
              <h4 class="font-weight-medium">
                Kategori
              </h4>
            </v-card-title>
            <v-divider></v-divider>
            <v-progress-circular
              v-if="isLoadingCategory"
              indeterminate
              color="primary"
              class="position-absolute"
              style="top:60%;left:50%;transform:translate(-50%,-50%);z-index:1"
            ></v-progress-circular>
            <v-list
              height="410"
              class="overflow-auto"
              rounded
            >
              <v-list-item-group>
                <v-list-item
                  class="pl-2"
                  @click="clickAll($event)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      Semua Kategori
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item-title>
                  <v-treeview
                    activatable
                    rounded
                    hoverable
                    color="primary"
                    :items="categories"
                    item-key="uuid"
                    item-text="name"
                    item-children="sub_categories"
                  >
                    <template
                      slot="label"
                      slot-scope="{ item }"
                    >
                      <a
                        v-if="!isLoadingLibrary"
                        class="d-flex justify-space-between align-center black--text"
                        @click.prevent="changeLibraryList(item.uuid, item.total_libraries)"
                      >
                        <span
                          class="d-inline-block text-truncate"
                          style="max-width: 150px"
                        >{{ item.name }}</span>
                        <v-chip
                          color="primary"
                        >
                          {{ item.total_libraries }} Buku
                        </v-chip>
                      </a>
                      <a
                        v-else
                        class="d-flex justify-space-between align-center black--text"
                        @click.prevent="changeLibraryList(item.uuid, item.total_libraries)"
                      >
                        <span
                          class="d-inline-block text-truncate"
                          style="max-width: 150px"
                        >{{ item.name }}</span>
                        <v-chip
                          color="primary"
                        >
                          {{ item.total_libraries }} Buku
                        </v-chip>
                      </a>
                    </template>
                  </v-treeview>
                </v-list-item-title>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-card
            outlined
            height="475"
          >
            <v-card-title>
              <h4 class="font-weight-medium">
                Pilih Buku
              </h4>
            </v-card-title>
            <v-simple-table
              class="overflow-auto"
              fixed-header
            >
              <thead style="border-top: none">
                <tr>
                  <th class="text-left">
                    <v-checkbox
                      v-model="allSelected"
                      @change="selectAll"
                    >
                    </v-checkbox>
                  </th>
                  <th class="text-left">
                    Type
                  </th>
                  <th class="text-left">
                    Nama Buku
                  </th>
                </tr>
              </thead>
            </v-simple-table>
            <v-simple-table
              id="scrollbar2"
              height="346"
              class="overflow-auto"
            >
              <template
                v-slot:default
              >
                <v-progress-circular
                  v-if="isLoadingLibrary"
                  indeterminate
                  color="primary"
                  class="position-absolute"
                  style="top:60%;left:50%;transform:translate(-50%,-50%);z-index:1"
                ></v-progress-circular>
                <tbody v-else>
                  <tr
                    v-for="(library, index) in libraries"
                    :key="index"
                  >
                    <td>
                      <v-checkbox
                        v-model="librariesToAdd"
                        :value="library.uuid"
                        @change="handleCheckboxChange(library.uuid, librariesToAdd)"
                      >
                      </v-checkbox>
                    </td>
                    <!-- <td>{{ index+1 }}</td> -->
                    <td>
                      <div class="d-flex justify-content-center">
                        <v-chip
                          outlined
                          :class="`${getBadgeType(library.type)} text-capitalize`"
                        >
                          {{ library.type }}
                        </v-chip>
                      </div>
                    </td>
                    <td>{{ library.name }}</td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <infinite-loading
                        class="d-flex justify-center"
                        @infinite="infiniteHandler"
                      >
                        <div slot="no-results"></div>
                        <div slot="no-more"></div>
                      </infinite-loading>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          md="6"
          cols="12"
        >
          <v-card outlined>
            <v-card-text>
              <h4>Buku yang dipilih : {{ librariesToAdd.length ? librariesToAdd.length : 0 }} dari {{ totalBook }}</h4>
              <span>Kamu Telah memilih buku sesuai jumlah buku dari paket</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <div class="notice">
            <h3>Keterangan :</h3>
            <div class="d-flex justify-space-between">
              <div
                v-for="(notice) in notices"
                :key="notice.title"
              >
                <div class="d-flex align-center">
                  <v-checkbox
                    v-model="notice.isChecked"
                    :disabled="notice.disable"
                  ></v-checkbox>
                  <small>{{ notice.title }}</small>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-center mt-4">
        <v-btn
          color="primary"
          class="mx-2"
          large
          :disabled="isLoadingButton"
          :loading="isLoadingButton"
          @click="save"
        >
          Simpan
        </v-btn>
        <v-btn
          color="primary"
          outlined
          class="mx-2"
          large
          @click="$emit('prev-tab')"
        >
          Sebelumnya
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import currencyToIntFormat from '@/utils/currency/currencyToIntFormat'
import toCurrencyFormat from '@/utils/currency/toCurrencyFormat'
import { mdiBook, mdiBookMusic, mdiFileVideo, mdiMagnify } from '@mdi/js'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  components: {
    InfiniteLoading,
  },
  props: {
    packetData: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isSelectAll: false,
      icons: {
        mdiBook,
        mdiBookMusic,
        mdiFileVideo,
        mdiMagnify,
      },
      scrollable: true,
      packetOptionDialog: false,
      categories: [],
      libraries: [],
      librariesSelected: [],
      isLoadingLibrary: false,
      isLoadingCategory: false,
      isLoadingButton: false,
      isLoadingPacket: false,
      searchDebounce: '',
      timeout: null,
      packet: {
        uuid: null,
        name: '',
        description: '',
        level: '',
        total_library: 0,
        price: 0,
        library: [],
      },
      notices: [
        { title: 'Buku yang akan dibeli', disable: false, isChecked: true },
        { title: 'Buku yang sudah dibeli', disable: true, isChecked: true },
        { title: 'Buku yang tidak dibeli', disable: false, isChecked: false },
      ],
      totalBook: 0,
      totalBookActive: 0,
      librariesToAdd: [],
      librariesToRemove: [],
      totalItemsLib: 0,
      totalPagesLib: 100,
      pageLib: 1,
      pageLibCategory: 0,
      page: 1,
      per_page: 100,
      changeUuid: null,
      categoryUuid: null,
      allSelected: false,
    }
  },
  computed: {
    price() {
      return toCurrencyFormat(this.packet.price)
    },
    search: {
      get() {
        return this.searchDebounce
      },
      set(val) {
        this.scrollable = false
        if (val.length > 3 || val.length === 0) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.searchDebounce = val
            this.listLibrary({ library: this.searchDebounce })
          }, 500)
        }
      },
    },
  },
  watch: {
    packetData() {
      this.packet = this.packetData
      this.getSelectedLibraries()
    },
  },
  mounted() {
    this.listCategory()
    this.packet = this.packetData
    this.getSelectedLibraries()
    this.listLibrary()
  },
  methods: {
    async infiniteHandler(state) {
      this.page += 1

      if (this.categoryUuid) {
        await this.$services.ApiServices.getParams('librarycategory', this.categoryUuid, {
          per_page: this.per_page,
          page: this.page,
        }).then(({ data }) => {
          if (data.data.length) {
            this.libraries = [...this.libraries, ...data.data]
            this.libraries = this.libraries.map(item => ({
              ...item,
              typeLibrary: {
                audio: item.audio,
                video: item.video,
                ebook: item.ebook,
              },
            }))
            this.isLoadingLibrary = false
            state.loaded()
          } else {
            state.complete()
          }
        })
        await this.selectAll()
      } else {
        await this.$services.ApiServices.list('library', {
          per_page: this.per_page,
          page: this.page,
        }).then(({ data }) => {
          if (data.data.length) {
            this.libraries = [...this.libraries, ...data.data]
            this.libraries = this.libraries.map(item => ({
              ...item,
              typeLibrary: {
                audio: item.audio,
                video: item.video,
                ebook: item.ebook,
              },
            }))
            this.isLoadingLibrary = false
            state.loaded()
          } else {
            state.complete()
          }
        })
      }
    },
    // checkButton() {
    //   this.librariesToAdd = []
    // },
    showFormAdd() {
      this.packetFormDialog = true
    },
    async listCategory() {
      this.isLoadingCategory = true
      await this.$services.ApiServices.list('category', { per_page: 'all', parent: 1 }).then(
        ({ data }) => {
          this.categories = data.data
        },
        err => console.error(err),
      )
      this.isLoadingCategory = false
    },
    clickAll(event, clickable) {
      this.scrollable = true
      this.listLibrary()
    },
    getBadgeType(type) {
      if (type === 'ebook') {
        return 'v-chip-light-bg warning--text'
      }
      if (type === 'audio') {
        return 'v-chip-light-bg success--text'
      }

      return 'v-chip-light-bg primary--text'
    },
    getTextType(type) {
      if (type === null || type === 'null' || type === '') {
        return '-'
      }

      return '+'
    },
    getIconType(icon) {
      if (icon === 'ebook') {
        return mdiBook
      }
      if (icon === 'audio') {
        return mdiBookMusic
      }

      return mdiFileVideo
    },
    async listLibrary(params = {}) {
      this.isLoadingLibrary = true
      await this.$services.ApiServices.list('library', {
        ...params,
        per_page: this.per_page,
        page: this.page,
      }).then(
        async ({ data }) => {
          this.libraries = data.data.map(item => ({
            ...item,
            typeLibrary: {
              audio: item.audio,
              video: item.video,
              ebook: item.ebook,
            },
          }))

          // this.libraries.sort((a, b) => Number(b.selected) - Number(a.selected))
          this.totalItemsLib = data.meta.total
          this.totalPagesLib = data.meta.per_page
          this.totalBook = data.meta.total
        },
        err => console.error(err),
      )
      this.isLoadingLibrary = false
    },
    // async onScrollEnd(entries, observer) {
    //   if (
    //     // eslint-disable-next-line operator-linebreak
    //     entries[0].isIntersecting &&
    //     // eslint-disable-next-line operator-linebreak
    //     this.totalItemsLib > this.totalPagesLib &&
    //     // eslint-disable-next-line operator-linebreak
    //     this.libraries.length < this.totalItemsLib &&
    //     // eslint-disable-next-line operator-linebreak
    //     this.scrollable
    //   ) {
    //     setTimeout(() => {
    //       this.isLoadingLibrary = true
    //     }, 300)
    //     this.pageLib += 1
    //     await this.$services.ApiServices.list('library', {
    //       per_page: this.per_page,
    //       page: this.pageLib,
    //     }).then(
    //       ({ data }) => {
    //         if (data.data.length > 0) {
    //           this.libraries = [...this.libraries, ...data.data]
    //         }
    //         this.isLoadingLibrary = false
    //       },
    //       err => console.error(err),
    //     )
    //   }
    // },
    async changeLibraryList(uuid, total) {
      this.categoryUuid = uuid
      this.totalBookActive = total
      this.scrollable = false
      this.isLoadingLibrary = true
      this.page = 1
      if (this.totalBookActive < 10 || this.totalBookActive === 10) {
        await this.$services.ApiServices.getParams('librarycategory', uuid, {
          per_page: this.per_page,
          page: this.page,
        }).then(
          ({ data }) => {
            const librariesData = data.data
            librariesData.forEach(item => {
              item.selected = this.librariesToAdd.includes(item.uuid)
              if (!item.selected && !this.packetData.uuid && this.allSelected) this.librariesToAdd.push(item.uuid)
            })
            this.libraries = data.data.map(item => ({
              ...item,
              typeLibrary: {
                audio: item.audio,
                video: item.video,
                ebook: item.ebook,
              },
            }))
            // this.libraries.sort((a, b) => Number(b.selected) - Number(a.selected))
            this.totalItemsLib = data.meta.total
            this.totalPagesLib = data.meta.per_page
            // this.packet.total_library = this.librariesToAdd.length
          },
          err => console.error(err),
        )
      } else {
        await this.$services.ApiServices.getParams('librarycategory', uuid, {
          per_page: this.per_page,
          page: this.page,
        }).then(
          ({ data }) => {
            const librariesData = data.data
            librariesData.forEach(item => {
              item.selected = this.librariesToAdd.includes(item.uuid)
              if (!item.selected && !this.packetData.uuid && this.allSelected) this.librariesToAdd.push(item.uuid)
            })
            this.libraries = data.data.map(item => ({
              ...item,
              typeLibrary: {
                audio: item.audio,
                video: item.video,
                ebook: item.ebook,
              },
            }))
            // this.libraries.sort((a, b) => Number(b.selected) - Number(a.selected))
            this.totalItemsLib = data.meta.total
            this.totalPagesLib = data.meta.per_page
            // this.packet.total_library = this.librariesToAdd.length
          },
          err => console.error(err),
        )
      }

      // this.pageLibCategory = 0
      // this.getSelectedLibraries()
      this.isLoadingLibrary = false
    },
    handleCheckboxChange(libraryUuid, librariesToAdd) {
      // console.log(libraryUuid)
      // console.log(librariesToAdd)
    },
    async selectAll() {
      if (this.allSelected) {
        const selected = this.libraries.map(library => library.uuid)
        this.librariesToAdd = selected
      } else {
        this.librariesToAdd = []
      }
    },
    async getSelectedLibraries() {
      this.librariesToAdd = []

      await this.$services.ApiServices.get('packet', this.packetData.uuid).then(
        async ({ data }) => {
          if (data.data.library) {
            await data.data.library.map(item => this.librariesToAdd.push(item.uuid))
          }

          this.packet.total_library = this.librariesToAdd.length
        },
        err => console.error(err),
      )
    },
    async getPacket() {
      await this.$services.ApiServices.get('packet', this.packetData.uuid).then(
        ({ data }) => {
          this.packet = data.data
        },
        err => console.error(err),
      )
    },
    async save() {
      this.isLoadingButton = true
      let isUpdate = false
      this.packet.total_book = this.librariesToAdd.length
      this.packet.price = currencyToIntFormat(this.packet.price)
      this.packet.level_uuid = this.packet.level
      if (!this.packet.uuid) {
        await this.$services.ApiServices.add('packet', this.packet).then(
          ({ data }) => {
            this.packet = data.data
          },
          err => console.error(err),
        )
      } else {
        isUpdate = true
        await this.$services.ApiServices.update('packet', this.packet, this.packet.uuid).then(
          ({ data }) => {
            this.packet = data.data
          },
          err => console.error(err),
        )
      }

      this.formData = new FormData()
      this.librariesToAdd.forEach(item => {
        this.formData.append('library_uuid[]', item)
      })
      this.formData.append('package_uuid', this.packet.uuid)
      await this.$services.ApiServices.add('packetLibrary', this.formData).then(
        res => null,
        err => console.error(err),
      )
      this.librariesToAdd = []
      this.showSnackbar({
        text: isUpdate ? 'Update Packet Has Successfully' : 'Add Packet Has Successfully',
        color: 'success',
      })
      this.isLoadingButton = false
      this.$emit('close-dialog')
      this.$emit('success-save')
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style scoped>
.packet-header .header-left ul {
  padding-left: 0;
}
.disable {
  pointer-events: none;
  cursor: default;
}
.packet-header .header-left ul li,
.packet-header .header-right ul li {
  list-style: none;
  margin-bottom: 1rem;
}
.v-application::v-deep a {
  color: #746d7d;
  text-decoration: none;
}
.v-list-item {
  min-height: 40px;
  margin-bottom: 0 !important;
}
.list-item-active {
  cursor: pointer;
}
.v-chip {
  cursor: pointer;
}
</style>
